<template>
  <div v-if="d_usrAuthWdm">
    <template v-if="d_userAuthorizationListLoading">
      <b-spinner variant="primary" style="width: 30px; height: 30px;" label="Searching"></b-spinner>
    </template>
    <template v-else>
      <!-- <b-button @click="d_isAdmin = !d_isAdmin" >d_isAdmin reverse</b-button> -->
      <b-row v-if="d_userAuthorizationList.auth_type_list.length || d_isAdmin" style="margin: 15px; padding: 20px 10px;">
        <b-col sm="12" lg="12">
          <strong style="font-size: 20px;">
            {{ StoreLangTranslation.data['new_assign'][StoreLang] }}
          </strong>
          <hr/>
          <b-row>
            <b-col sm="12" lg="12">
              <strong style="font-size: 20px;">
                {{ StoreLangTranslation.data['user'][StoreLang] }}
              </strong>
            </b-col>
          </b-row>
          <b-row style="min-height: 100px;">
            <b-col sm="12" lg="6">
              <b-row>
                <b-col sm="12" lg="12">
                  <b-input-group>
                    <b-form-input type="text" class="form-control" v-model="d_searchUser" :placeholder="StoreLangTranslation.data['search_user'][StoreLang]"></b-form-input>
                    <b-input-group-append>
                      <b-button variant="secondary" @click="f_searchUser()">
                        <i class="fa fa-search"></i>
                      </b-button>
                      <b-button variant="danger" @click="f_clearSearchUser()">
                        <i class="fa fa-trash"></i>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
                <template v-if="d_userSearched">
                  <template v-if="d_filteredUsers.length">
                    <b-col sm="12" lg="12">
                      <b-row v-for="(user, index) in d_filteredUsers" :key="index">
                        <b-col sm="12" lg="12">
                          <span v-if="!d_newAssign.users.some(assignedUser => assignedUser === user.username)" style="cursor: pointer;" @click="f_addUser(user)">
                            <i class="fa fa-arrow-right"></i>
                            {{ user.username }}
                          </span>
                        </b-col>
                      </b-row>
                    </b-col>
                  </template>
                  <template v-else>
                    <b-col sm="12" lg="12">
                      <b-row>
                        <b-col sm="12" lg="12">
                          <span style="color: red;">
                            {{ StoreLangTranslation.data['user_not_found'][StoreLang] }}
                          </span>
                        </b-col>
                      </b-row>
                    </b-col>
                  </template>
                </template>
              </b-row>
            </b-col>
            <b-col sm="12" lg="6">
              <b-row v-for="user in d_newAssign.users">
                <b-col sm="12" lg="12">
                  <span style="cursor: pointer; color: green;" @click="f_removeUser(user)">
                    <i class="fa fa-arrow-left"></i>
                    {{ user }}
                  </span>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" lg="12">
              <strong style="font-size: 20px;">
                {{ StoreLangTranslation.data['auth'][StoreLang] }}
              </strong>
            </b-col>
          </b-row>
          <b-row style="min-height: 100px;">
            <b-col sm="12" lg="6">
              <b-row>
                <b-col sm="12" lg="12">
                  <b-input-group>
                    <b-form-input type="text" class="form-control" v-model="d_searchAuth" :placeholder="StoreLangTranslation.data['search_auth'][StoreLang]"></b-form-input>
                    <b-input-group-append>
                      <b-button variant="secondary" @click="f_searchAuth()">
                        <i class="fa fa-search"></i>
                      </b-button>
                      <b-button variant="danger" @click="f_clearSearchAuth()">
                        <i class="fa fa-trash"></i>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
                <template v-if="d_authSearched">
                  <template v-if="d_filteredAuths.auth_type_list.length || d_isAdmin">
                    <b-row style="margin: 3px;">
                      <b-col sm="12" lg="12">
                        <template>
                          <template v-for="admin_auth_type in d_usrAuthWdm['0']['parameters']['2']['options']">
                            <b-row style="margin-top: 5px;" v-if="d_isAdmin || admin_auth_type.value in d_filteredAuths.auth_type_list || admin_auth_type.value in d_adminAuths">
                              <b-col sm="12" lg="12">
                                <strong style="font-size: 20px;">
                                  {{ admin_auth_type['translation'][StoreLang] }}
                                </strong>
                                <i style="color: green; cursor: pointer;" @click="f_addCreateAuth(admin_auth_type.value)" v-if="admin_auth_type.value !== '1' && d_isAdmin">
                                  {{ StoreLangTranslation.data['create_auth'][StoreLang] }}
                                </i>
                              </b-col>
                            </b-row>
                            <template v-if="admin_auth_type.value == '2'">
                              <b-row v-for="(userShop, userShopIndex) in d_usrShpList" :key="'user_shop_' + userShopIndex">
                                <b-col sm="12" lg="12">
                                  <span style="cursor: pointer;" @click="f_addCreateAuth(admin_auth_type.value, userShop['client_id'])">
                                    <i class="fa fa-arrow-right"></i>
                                    {{ userShop['data']['0']['31']['lang'][StoreLang] }}
                                  </span>
                                </b-col>
                              </b-row>
                            </template>
                            <b-row v-for="(auth, index) in d_filteredAuths[admin_auth_type['value']]" :key="admin_auth_type['value'] + '_' + index">
                              <b-col sm="12" lg="12">
                                <span v-if="!d_newAssign.auths.includes(auth)" style="cursor: pointer;" @click="f_addAuth(auth)">
                                  <i class="fa fa-arrow-right"></i>
                                  {{ auth['data']['0']['2']['val']['value'] == '1' ? auth['data']['0']['34']['val'] : (auth['data']['0']['2']['val']['value'] == '0' ? auth['data']['0']['1']['val'] : (auth['data']['0']['2']['val']['value'] == '2' ? auth['data']['0']['1']['val'] : '')) }}
                                </span>
                              </b-col>
                            </b-row>
                            <hr/>
                          </template>



                        </template>
                      </b-col>
                    </b-row>
                  </template>
                  <template v-else>
                    <b-col sm="12" lg="12">
                      <b-row>
                        <b-col sm="12" lg="12">
                          <span style="color: red;">
                            {{ StoreLangTranslation.data['auth_not_found'][StoreLang] }}
                          </span>
                        </b-col>
                      </b-row>
                    </b-col>
                  </template>
                </template>
              </b-row>
            </b-col>
            <b-col sm="12" lg="6">
              <b-row v-for="(createAuth, createAuthIndex) in d_createAssign">
                <b-col sm="12" lg="12">
                  <b-row>
                    <b-col sm="12" lg="12">
                      <span style="cursor: pointer; color: green;" @click="f_removeCreateAuth(createAuthIndex)">
                        <i class="fa fa-arrow-left"></i>
                        {{ StoreLangTranslation.data['new_auth'][StoreLang] }} ({{ d_usrAuthWdm['0']['parameters']['2']['options'][createAuth['auth_type']]['translation'][StoreLang] }})
                      </span>
                    </b-col>
                  </b-row>
                   <b-row style="margin: 3px;">
                    <b-col sm="12" lg="4">
                      {{ StoreLangTranslation.data['auth_id'][StoreLang] }}
                    </b-col>
                    <b-col sm="12" lg="8">
                      <!-- <b-form-input :disabled="d_createAssign[createAuthIndex]['auth_id'] && !d_isAdmin ? true : false" type="text" v-model="d_createAssign[createAuthIndex]['auth_id']"></b-form-input> -->
                      <b-form-input :disabled="!d_isAdmin ? true : false" type="text" v-model="d_createAssign[createAuthIndex]['auth_id']"></b-form-input>
                    </b-col>
                  </b-row>
                   <b-row style="margin: 3px;">
                    <b-col sm="12" lg="4">
                      {{ StoreLangTranslation.data['auth_code'][StoreLang] }}
                    </b-col>
                    <b-col sm="12" lg="8">
                      <b-form-select v-model="d_createAssign[createAuthIndex]['auth_code']">
                        <option v-for="auth_code in d_auth_code_list[createAuth['auth_type']]" :value="auth_code['code']">
                          {{ auth_code['name']['translation'][StoreLang] }}
                        </option>
                      </b-form-select>
                    </b-col>
                  </b-row>
                   <b-row style="margin: 3px;">
                    <b-col sm="12" lg="4">
                      {{ StoreLangTranslation.data['auth_label'][StoreLang] }}
                    </b-col>
                    <b-col sm="12" lg="8">
                      <b-form-input type="text" v-model="d_createAssign[createAuthIndex]['auth_label']"></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row style="margin: 3px;">
                    <b-col sm="12" lg="4">
                      {{ StoreLangTranslation.data['can_assign_auth'][StoreLang] }}
                    </b-col>
                    <b-col sm="12" lg="8">
                      <b-form-select v-model="d_createAssign[createAuthIndex]['can_assign_auth']">
                        <option value="0" selected>
                          {{ StoreLangTranslation.data['no'][StoreLang] }}
                        </option>
                        <option value="1">
                          {{ StoreLangTranslation.data['yes'][StoreLang] }}
                        </option>
                      </b-form-select>
                    </b-col>
                  </b-row>
                  <b-row style="margin: 3px;">
                    <b-col sm="12" lg="4">
                      {{ StoreLangTranslation.data['auth_count_create'][StoreLang] }}
                    </b-col>
                    <b-col sm="12" lg="8">
                      <b-form-select v-model="d_createAssign[createAuthIndex]['auth_count_create']">
                        <option value="0">
                          {{ StoreLangTranslation.data['no'][StoreLang] }}
                        </option>
                        <option value="1">
                          {{ StoreLangTranslation.data['yes'][StoreLang] }}
                        </option>
                      </b-form-select>
                    </b-col>
                  </b-row>
                  <b-row v-if="d_createAssign[createAuthIndex]['auth_count_create'] == '1'" style="margin: 3px;">
                    <b-col sm="12" lg="4">
                      {{ StoreLangTranslation.data['auth_count'][StoreLang] }}
                    </b-col>
                    <b-col sm="12" lg="8">
                      <b-form-input type="number" v-model="d_createAssign[createAuthIndex]['auth_count']"></b-form-input>
                    </b-col>
                  </b-row>
                  <!-- sadece admin için açılabilir. kullanıcının var olan bir yetkisinin sayısı yok sayılıp restart edilmesi mümkün çünkü -->
              
                  <!-- <b-row v-if="d_createAssign[createAuthIndex]['auth_count_create'] == '1'" style="margin: 3px;">
                    <b-col sm="12" lg="4">
                      {{ StoreLangTranslation.data['auth_count_type'][StoreLang] }}
                    </b-col>
                    <b-col sm="12" lg="8">
                      <select v-model="d_createAssign[createAuthIndex]['auth_count_type']" >
                        <option value="0" >
                          {{ 0 }}
                        </option>
                        <option value="1">
                          {{ 1 }}
                        </option>
                        <option value="2">
                          {{ 2 }}
                        </option>
                      </select>
                    </b-col>
                  </b-row> -->
                  <b-row style="margin: 3px;">
                    <b-col sm="12" lg="4">
                      {{ StoreLangTranslation.data['start_date'][StoreLang] }}
                    </b-col>
                    <b-col sm="12" lg="8">
                      <b-form-datepicker 
                      v-model="d_createAssign[createAuthIndex]['start_date']"
                      :locale="StoreLang"
                      today-button
                      reset-button
                      close-button/>
                    </b-col>
                  </b-row>
                  <b-row style="margin: 3px;">
                    <b-col sm="12" lg="4">
                      {{ StoreLangTranslation.data['end_date'][StoreLang] }}
                    </b-col>
                    <b-col sm="12" lg="8">
                      <b-form-datepicker 
                      v-model="d_createAssign[createAuthIndex]['end_date']"
                      :locale="StoreLang"
                      today-button
                      reset-button
                      close-button/>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row v-for="(assignAuth, assignAuthIndex) in d_newAssign.auths">
                <b-col sm="12" lg="12">
                  <b-row>
                    <b-col sm="12" lg="12">
                      <span style="cursor: pointer; color: green;" @click="f_removeAuth(assignAuth)">
                        <i class="fa fa-arrow-left"></i>
                        {{ assignAuth['data']['0']['2']['val']['value'] == '1' ? assignAuth['data']['0']['34']['val'] : (assignAuth['data']['0']['2']['val']['value'] == '0' ? assignAuth['data']['0']['1']['val'] : (assignAuth['data']['0']['2']['val']['value'] == '2' ? assignAuth['data']['0']['1']['val'] : '')) }}
                      </span>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" lg="12">
                      <b-row style="margin: 3px;">
                        <b-col sm="12" lg="4">
                          {{ StoreLangTranslation.data['can_assign_auth'][StoreLang] }}
                        </b-col>
                        <b-col sm="12" lg="8">
                          <b-form-select v-model="d_newAssign['auth_params'][assignAuthIndex]['can_assign_auth']">
                            <option value="0" selected>
                              {{ StoreLangTranslation.data['no'][StoreLang] }}
                            </option>
                            <option value="1">
                              {{ StoreLangTranslation.data['yes'][StoreLang] }}
                            </option>
                          </b-form-select>
                        </b-col>
                      </b-row>
                      <b-row style="margin: 3px;">
                        <b-col sm="12" lg="4">
                          {{ StoreLangTranslation.data['auth_count_create'][StoreLang] }}
                        </b-col>
                        <b-col sm="12" lg="8">
                          <b-form-select v-model="d_newAssign['auth_params'][assignAuthIndex]['auth_count_create']">
                            <option value="0">
                              {{ StoreLangTranslation.data['no'][StoreLang] }}
                            </option>
                            <option value="1">
                              {{ StoreLangTranslation.data['yes'][StoreLang] }}
                            </option>
                          </b-form-select>
                        </b-col>
                      </b-row>
                      <b-row v-if="d_newAssign['auth_params'][assignAuthIndex]['auth_count_create'] == '1'" style="margin: 3px;">
                        <b-col sm="12" lg="4">
                          {{ StoreLangTranslation.data['auth_count'][StoreLang] }}
                        </b-col>
                        <b-col sm="12" lg="8">
                          <b-form-input type="number" v-model="d_newAssign['auth_params'][assignAuthIndex]['auth_count']"></b-form-input>
                        </b-col>
                      </b-row>
                        <!-- sadece admin için açılabilir. kullanıcının var olan bir yetkisinin sayısı yok sayılıp restart edilmesi mümkün çünkü -->
                      <!-- <b-row v-if="d_newAssign['auth_params'][assignAuthIndex]['auth_count_create'] == '1'" style="margin: 3px;">
                        <b-col sm="12" lg="4">
                          {{ StoreLangTranslation.data['auth_count_type'][StoreLang] }}
                        </b-col>
                        <b-col sm="12" lg="8">
                          <select v-model="d_newAssign['auth_params'][assignAuthIndex]['auth_count_type']" >
                            <option value="0" >
                              {{ 0 }}
                            </option>
                            <option value="1">
                              {{ 1 }}
                            </option>
                            <option value="2">
                              {{ 2 }}
                            </option>
                          </select>
                        </b-col>
                      </b-row> -->
                      <b-row style="margin: 3px;">
                        <b-col sm="12" lg="4">
                          {{ StoreLangTranslation.data['start_date'][StoreLang] }}
                        </b-col>
                        <b-col sm="12" lg="8">
                          <b-form-datepicker 
                          v-model="d_newAssign['auth_params'][assignAuthIndex]['start_date']"
                          :locale="StoreLang"
                          today-button
                          reset-button
                          close-button/>
                        </b-col>
                      </b-row>
                      <b-row style="margin: 3px;">
                        <b-col sm="12" lg="4">
                          {{ StoreLangTranslation.data['end_date'][StoreLang] }}
                        </b-col>
                        <b-col sm="12" lg="8">
                          <b-form-datepicker 
                          v-model="d_newAssign['auth_params'][assignAuthIndex]['end_date']"
                          :locale="StoreLang"
                          today-button
                          reset-button
                          close-button/>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12" lg="10">
                </b-col>
                <b-col sm="12" lg="2">
                  <b-button variant="primary" @click="f_authAssign()" v-show="d_newAssign.users.length && (d_newAssign.auths.length || d_createAssign.length)">
                    {{ StoreLangTranslation.data['save'][StoreLang] }}
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row v-else style="text-align: center;">
        <b-col sm="12" lg="12">
          {{ StoreLangTranslation.data['no_auth_to_assign'][StoreLang] }}
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>

import { API_BASE_URL } from '@/config';
import { ServiceFunctions } from '@/wam/modules/service';
import { ClsWdm } from '@/wam/modules/wdm';
import store from '@/store';
import {
  default as Page404
} from '@/wam/components/widgets/Page404';
import {
  default as Login
} from '@/wam/views/login';
import { mapGetters } from 'vuex';
export default {
  name: 'user_settings',
  computed: mapGetters({
    StoreDevice: 'StoreDevice',
    StoreFrontendSettings: 'StoreFrontendSettings',
    StoreLang: 'StoreLang',
    StoreLangTranslation: 'StoreLangTranslation',
    StoreLangList: 'StoreLangList',
  }),
    components: {
    Page404,
    Login,
  },
  props: {},
  data () {
    return {
      d_user: '',
      d_isAdmin: false,
      d_adminAuths: {},
      d_userAuthorizationList: {
        'auth_type_list': []
      },
      d_userAuthorizationListLoading: false,
      d_searchUser: '',
      d_searchAuth: '',
      d_newAssign: {
        'users': [],
        'auths': [],
        'auth_params': []
      },
      d_createAssign: [],
      d_filteredUsers: [],
      d_filteredAuths: {
        'auth_type_list': []
      },
      d_userSearched: false,
      d_authSearched: false,
      d_usrShpList: [],
      d_auth_code_list: {
        '0': [
          {
            'code': 'admin',
            'name': {
              'translation': {
                'en': 'admin',
                'tr': 'admin'
              }
            },
          },
          {
            'code': 'website_edit',
            'name': {
              'translation': {
                'en': 'website edit',
                'tr': 'website düzenleme'
              }
            },
          },
          {
            'code': 'website_admin',
            'name': {
              'translation': {
                'en': 'website admin',
                'tr': 'website admin'
              }
            },
          },
          {
            'code': 'register',
            'name': {
              'translation': {
                'en': 'register',
                'tr': 'register'
              }
            },
          },
          {
            'code': 'shop_new',
            'name': {
              'translation': {
                'en': 'new shop',
                'tr': 'yeni mağaza'
              }
            },
          },
          {
            'code': 'db_admin',
            'name': {
              'translation': {
                'en': 'db admin',
                'tr': 'db admin'
              }
            },
          },
          {
            'code': 'shop_settings_edit',
            'name': {
              'translation': {
                'en': 'shop settings edit',
                'tr': 'magaza genel ayarlarını düzenleme'
              }
            },
          },
          {
            'code': 'shop_bundle_add_edit',
            'name': {
              'translation': {
                'en': 'shop bundle add edit',
                'tr': 'magaza paket ekleme düzenleme'
              }
            },
          },
          {
            'code': 'shop_card_view',
            'name': {
              'translation': {
                'en': 'shop card view',
                'tr': 'magaza sipariş görüntüleme'
              }
            },
          },
          {
            'code': 'shop_card_user_bundle_add_edit',
            'name': {
              'translation': {
                'en': 'shop card user bundle add edit',
                'tr': 'magaza kullanıcı sipariş paket ekleme düzenleme'
              }
            },
          },
          {
            'code': 'shop_order_bundle_status_management',
            'name': {
              'translation': {
                'en': 'shop order bundle status management',
                'tr': 'magaza sipariş paket ürün durumları yönetimi'
              }
            },
          },
          {
            'code': 'shop_admin',
            'name': {
              'translation': {
                'en': 'shop admin',
                'tr': 'magaza admin'
              }
            },
          }
        ],
        '1': [
          {
            'code': 'wanalyzer_stats_view',
            'name': {
              'translation': {
                'en': 'wanalyzer stats view',
                'tr': 'wanalyzer stats view'
              }
            },
          },
          {
            'code': 'wanalyzer_dataset_filter',
            'name': {
              'translation': {
                'en': 'wanalyzer dataset filter',
                'tr': 'wanalyzer dataset filter'
              }
            },
          },
          {
            'code': 'wanalyzer_dataset_stats_query',
            'name': {
              'translation': {
                'en': 'wanalyzer dataset_stats query',
                'tr': 'wanalyzer dataset_stats query'
              }
            },
          },
          {
            'code': 'wanalyzer_machinelearning_query',
            'name': {
              'translation': {
                'en': 'wanalyzer machinelearning query',
                'tr': 'wanalyzer machinelearning query'
              }
            },
          },
          {
            'code': 'wanalyzer_create_query',
            'name': {
              'translation': {
                'en': 'wanalyzer create query',
                'tr': 'wanalyzer create query'
              }
            },
          },
        ],
        '2': [
          {
            'code': 'shop_settings_edit',
            'name': {
              'translation': {
                'en': 'shop settings edit',
                'tr': 'magaza genel ayarlarını düzenleme'
              }
            },
          },
          {
            'code': 'shop_bundle_add_edit',
            'name': {
              'translation': {
                'en': 'shop bundle add edit',
                'tr': 'magaza paket ekleme düzenleme'
              }
            },
          },
          {
            'code': 'shop_card_view',
            'name': {
              'translation': {
                'en': 'shop card view',
                'tr': 'magaza sipariş görüntüleme'
              }
            },
          },
          {
            'code': 'shop_card_user_bundle_add_edit',
            'name': {
              'translation': {
                'en': 'shop card user bundle add edit',
                'tr': 'magaza kullanıcı sipariş paket ekleme düzenleme'
              }
            },
          },
          {
            'code': 'shop_order_bundle_status_management',
            'name': {
              'translation': {
                'en': 'shop order bundle status management',
                'tr': 'magaza sipariş paket ürün durumları yönetimi'
              }
            },
          },
          {
            'code': 'shop_admin',
            'name': {
              'translation': {
                'en': 'shop admin',
                'tr': 'magaza admin'
              }
            },
          }
        ],
        '3': [
          {
            'code': 'website_edit',
            'name': {
              'translation': {
                'en': 'website edit',
                'tr': 'website düzenleme'
              }
            },
          },
          {
            'code': 'website_admin',
            'name': {
              'translation': {
                'en': 'website admin',
                'tr': 'website admin'
              }
            },
          },
          {
            'code': 'website_page_view',
            'name': {
              'translation': {
                'en': 'website page view',
                'tr': 'website sayfa görüntüleme'
              }
            },
          }
        ]
      },
      d_usrAuthWdm: '',
      d_frontendList: '',
      d_frontendClientID: '',
      d_frontendAuthWebsiteunitList: []
    };
  },
  created: function () {
    ClsWdm.getLayerWdm('usr_auth').then(resp_wdm_data => { this.d_usrAuthWdm = resp_wdm_data; });
    this.d_user = JSON.parse(localStorage.getItem('user'));
    if (this.d_user && this.d_user.username) {
      this.f_userShopList();
      this.f_frontendList();
      this.f_getUserAuthorizationList();
    }  
  },
  beforeMount () {},
  mounted: function () {},
  methods: {
    f_getUserAuthorizationList: function () {
      this.d_userAuthorizationListLoading = true;
      let query = 'user_client_id=' + this.d_user.username;
      query += '&auth_status=active';
      let data = {};
      this.d_userAuthorizationList = {
        'auth_type_list': []
      };
      ServiceFunctions.user_authorization_list(query, data)
        .then(resp => {
          this.d_userAuthorizationListLoading = false;
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            this.d_isAdmin = resp.data.list.some(item => item.auth_data.data["0"]["1"].val === "admin");
            // this.d_isAdmin = resp.data.list.some(function (item) {
            //   return item.auth_data.data["0"]["1"].val === "admin";
            // });
            for (let i in resp.data.list) {
              if (this.d_isAdmin) {
                let auth_type = resp.data.list[i].auth_data.data['0']['2']['val']['value']
                if (this.d_userAuthorizationList.auth_type_list.indexOf(auth_type) == -1) {
                  this.d_userAuthorizationList.auth_type_list.push(auth_type);
                  this.d_userAuthorizationList[auth_type] = [];
                }
                this.d_userAuthorizationList[auth_type].push(resp.data.list[i].auth_data)
                if (!(auth_type in this.d_adminAuths) && (auth_type == '0' || auth_type == '3') && resp.data.list[i].auth_data.data['0']['1']['val'] == 'website_admin') {
                  this.d_adminAuths['3'] = 'yes';
                }
                else if (!(auth_type in this.d_adminAuths) && auth_type == '2' && resp.data.list[i].auth_data.data['0']['1']['val'] == 'shop_admin') {
                  this.d_adminAuths['2'] = 'yes';
                }
              } else {
                let can_assign_auth = '';
                try {
                  can_assign_auth = resp.data.list[i].auth_data.data['0']['5']['val'];
                } 
                catch {
                }
                let end_date = '';
                try {
                  end_date = resp.data.list[i].auth_data.data['0']['9']['val'];
                } 
                catch {
                }
                let is_auth_still_ongoing = true
                if (end_date) {
                  const inputDate = new Date(end_date);
                  const today = new Date();
                  if (today > inputDate) {
                    is_auth_still_ongoing = false;
                  }
                }
                if (can_assign_auth['value'] == '1' && is_auth_still_ongoing) {
                  let auth_type = resp.data.list[i].auth_data.data['0']['2']['val']['value']
                  if (this.d_userAuthorizationList.auth_type_list.indexOf(auth_type) == -1) {
                    this.d_userAuthorizationList.auth_type_list.push(auth_type);
                    this.d_userAuthorizationList[auth_type] = [];
                  }
                  this.d_userAuthorizationList[auth_type].push(resp.data.list[i].auth_data)
                  // console.log(resp.data.list[i].auth_data.data['0']['1']['val'])
                  if (!(auth_type in this.d_adminAuths) && (auth_type == '0' || auth_type == '3') && resp.data.list[i].auth_data.data['0']['1']['val'] == 'website_admin') {
                    this.d_adminAuths['3'] = 'yes';
                  }
                  else if (!(auth_type in this.d_adminAuths) && auth_type == '2' && resp.data.list[i].auth_data.data['0']['1']['val'] == 'shop_admin') {
                    this.d_adminAuths['2'] = 'yes';
                  }
                }
              }
            }
            // console.log(this.d_userAuthorizationList);
            this.f_searchAuth();
          } else {
            let txt = 'status_code = ' + resp.data.status_code + '\n';
            txt += 'status_message = ' + resp.data.status_message;
            alert(txt);
          }
        });
    },
    f_userShopList: function () {
      let query = 'user_client_id=' + this.d_user.username;
      let data = {};
      ServiceFunctions.user_shop_list(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            if (resp.data.list) {
              this.d_usrShpList = resp.data.list;
              console.log(this.d_usrShpList);
              if (this.d_usrShpList.length) {
                this.d_adminAuths['2'] = 'yes';
              }
            } else {
              alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
            }
          } else {
            alert(resp.data.status_code + ' = ' + resp.data.status_message);
          }
        });
    },
    f_frontendList: function () {
      let query = '';
      let data = {};
      ServiceFunctions.frontend_list(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            if (resp.data.list) {
              this.d_frontendList = resp.data.list;
              this.d_frontendClientID = this.d_frontendList[0].client_id;
            } else {
              alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
            }
          } else {
            alert(resp.data.status_code + ' = ' + resp.data.status_message);
          }
        });
    },
    f_frontendWebsiteunitList: function () {
      if (this.d_frontendClientID) {
        let query = 'frontend_client_id=' + this.d_frontendClientID;
        let data = {};
        ServiceFunctions.frontend_websiteunit_list(query, data)
          .then(resp => {
            if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
              if (resp.data.list) {
                for (let web_unit in resp.data.list) {
                  if (resp.data.list[web_unit]['data']['0']['30'] && resp.data.list[web_unit]['data']['0']['30']['val'] && resp.data.list[web_unit]['data']['0']['30']['val']['value'] && resp.data.list[web_unit]['data']['0']['30']['val']['value'] == '1') {
                    this.d_frontendAuthWebsiteunitList.push(resp.data.list[web_unit]);
                  }
                }
                console.log(this.d_frontendAuthWebsiteunitList);
              } else {
                alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
              }
            } else {
              alert(resp.data.status_code + ' = ' + resp.data.status_message);
            }
          });
      }
    },
    f_searchUser: function () {
      if (this.d_searchUser) {
        this.d_userSearchLoading = true;
        const file_type = 'username';
        const match_type = 'include';
        const search_text = this.d_searchUser;
        let query = 'field_type=' + file_type + '&match_type=' + match_type;
        let data = {
          'search_text': search_text
        };
        
        ServiceFunctions.user_search(query, data)
          .then(resp => {
            this.d_userSearched = true;
            this.d_filteredUsers = [];
            this.d_userSearchLoading = false;
            if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
              // console.log(resp.data);
              this.d_filteredUsers = resp.data.list;
            }
          });
      } else {
        alert(this.StoreLangTranslation.data['username_cannot_be_empty'][this.StoreLang]);
      }
    },
    f_removeUser: function (user) {
      let removeIndex = this.d_newAssign.users.indexOf(user);
      if (removeIndex !== -1) {
        this.d_newAssign.users.splice(removeIndex, 1);
      }
    },
    f_removeAuth: function (auth) {
      let removeIndex = this.d_newAssign.auths.indexOf(auth);
      if (removeIndex !== -1) {
        this.d_newAssign.auth_params.splice(removeIndex, 1);
        this.d_newAssign.auths.splice(removeIndex, 1);
      }
    },
    f_clearSearchUser: function () {
      this.d_userSearched = false;
      this.d_searchUser = '';
    },
    f_clearSearchAuth: function () {
      this.d_authSearched = false;
      this.d_searchAuth = '';
    },
    f_addUser: function (user) {
      this.d_newAssign.users.push(user.username);
    },
    f_addAuth: function (auth) {
      let params = {
        'can_assign_auth': '0',
        'auth_count_create': '0',
        'auth_count': '0',
        'auth_count_type': '0',
        'start_date': '',
        'end_date': '' 
      }
      this.d_newAssign.auth_params.push(params)
      this.d_newAssign.auths.push(auth);
    },
    f_addCreateAuth: function (auth_type, auth_id = '') {
      let createAuth = {
        'auth_type': auth_type,
        'auth_id': '',
        'auth_code': '',
        'auth_label': '',
        'auth_label_lang': {
          'translation': {
            'en': '',
            'tr': ''
          }
        },
        'can_assign_auth': '0',
        'auth_count_create': '0',
        'auth_count': '0',
        'auth_count_type': '0',
        'start_date': '',
        'end_date': '' 
      }
      if (auth_id) {
        createAuth['auth_id'] = auth_id;
      }
      this.d_createAssign.push(createAuth);
    },
    f_removeCreateAuth: function (create_auth_index) {
      this.d_createAssign.splice(create_auth_index, 1);
    },
    f_searchAuth: function () {
      this.d_filteredAuths = {
        'auth_type_list': []
      };
      this.d_authSearched = true;
      let searchResult = {
        'auth_type_list': []
      };
      for (let auth_type in this.d_userAuthorizationList.auth_type_list) {
        for (let auth of this.d_userAuthorizationList[auth_type]) {
          if (auth['data']['0']['2']['val']['value'] == '1' && auth['data']['0']['34']['val'].toLowerCase().includes(this.d_searchAuth.toLocaleLowerCase())) {
            if (searchResult.auth_type_list.indexOf(auth_type) == -1) {
              searchResult.auth_type_list.push(auth_type);
              searchResult[auth_type] = [];
            }
            searchResult[auth_type].push(auth)
          } else if (auth['data']['0']['2']['val']['value'] == '0' && auth['data']['0']['1']['val'].toLowerCase().includes(this.d_searchAuth.toLocaleLowerCase())) {
            if (searchResult.auth_type_list.indexOf(auth_type) == -1) {
              searchResult.auth_type_list.push(auth_type);
              searchResult[auth_type] = [];
            }
            searchResult[auth_type].push(auth)
          }
        }
      }
      // console.log(searchResult)
      this.d_filteredAuths = searchResult;
    },
    f_authAssign: function () {
      let payloadAuthList = [];
      for (let assigningAuthIndex = 0; assigningAuthIndex < this.d_newAssign.auths.length; assigningAuthIndex++) {
        let payloadAuthListItem = {
          'auth_code': '',
          'auth_id': '',
          'auth_type': '',
          'auth_count_create': '',
          'can_assign_auth': '',
          'auth_count': '0',
          'auth_count_type': '0',
          'start_date': '',
          'end_date': '',
          'auth_label': '',
          'auth_label_lang': '',
          'auth_client_id': this.d_newAssign.auths[assigningAuthIndex]['client_id']
        };
        // ekrandaki input parametrelerinden gelen veriler
        if (this.d_newAssign.auth_params[assigningAuthIndex]['auth_count_create']) {
          payloadAuthListItem['auth_count_create'] = this.d_newAssign.auth_params[assigningAuthIndex]['auth_count_create'];
          payloadAuthListItem['auth_count_type'] = '2' //yetki sayısının üzerine eklenecek şekilde
        } else {
          payloadAuthListItem['auth_count_type'] = '0' //yetki adedi yok şeklinde
        }
        if (this.d_newAssign.auth_params[assigningAuthIndex]['auth_count']) {
          payloadAuthListItem['auth_count'] = this.d_newAssign.auth_params[assigningAuthIndex]['auth_count'];
        }
        if (this.d_newAssign.auth_params[assigningAuthIndex]['can_assign_auth']) {
          payloadAuthListItem['can_assign_auth'] = this.d_newAssign.auth_params[assigningAuthIndex]['can_assign_auth'];
        }
        // admin için yetki adet tipi aktifleştirilirse burası da açılacak ki oradaki inputtan alınsın
        // if (this.d_newAssign.auth_params[assigningAuthIndex]['auth_count_type']) {
        //   payloadAuthListItem['auth_count_type'] = this.d_newAssign.auth_params[assigningAuthIndex]['auth_count_type'];
        // }
        if (this.d_newAssign.auth_params[assigningAuthIndex]['start_date']) {
          payloadAuthListItem['start_date'] = this.d_newAssign.auth_params[assigningAuthIndex]['start_date'];
        }
        if (this.d_newAssign.auth_params[assigningAuthIndex]['end_date']) {
          payloadAuthListItem['end_date'] = this.d_newAssign.auth_params[assigningAuthIndex]['end_date'];
        }
        // auth dokümanı içerisinden gelen veriler
        if (this.d_newAssign.auths[assigningAuthIndex]['data']['0']['1'] && this.d_newAssign.auths[assigningAuthIndex]['data']['0']['1']['val']) {
          payloadAuthListItem['auth_code'] = this.d_newAssign.auths[assigningAuthIndex]['data']['0']['1']['val'];
        }
        if (this.d_newAssign.auths[assigningAuthIndex]['data']['0']['10'] && this.d_newAssign.auths[assigningAuthIndex]['data']['0']['10']['val']) {
          payloadAuthListItem['auth_id'] = this.d_newAssign.auths[assigningAuthIndex]['data']['0']['10']['val'];
        }
        if (this.d_newAssign.auths[assigningAuthIndex]['data']['0']['2'] && this.d_newAssign.auths[assigningAuthIndex]['data']['0']['2']['val'] && this.d_newAssign.auths[assigningAuthIndex]['data']['0']['2']['val']['value']) {
          payloadAuthListItem['auth_type'] = this.d_newAssign.auths[assigningAuthIndex]['data']['0']['2']['val']['value'];
        }
        if (this.d_newAssign.auths[assigningAuthIndex]['data']['0']['34'] && this.d_newAssign.auths[assigningAuthIndex]['data']['0']['34']['val']) {
          payloadAuthListItem['auth_label'] = this.d_newAssign.auths[assigningAuthIndex]['data']['0']['34']['val'];
        }
        if (this.d_newAssign.auths[assigningAuthIndex]['data']['0']['34'] && this.d_newAssign.auths[assigningAuthIndex]['data']['0']['34']['lang']) {
          payloadAuthListItem['auth_label_lang'] = this.d_newAssign.auths[assigningAuthIndex]['data']['0']['34']['lang'];
        }
        if (this.d_newAssign.auths[assigningAuthIndex]['data']['0']['11'] && this.d_newAssign.auths[assigningAuthIndex]['data']['0']['11']['val']) {
          payloadAuthListItem['old_owner'] = this.d_newAssign.auths[assigningAuthIndex]['data']['0']['11']['val'];
        }
        payloadAuthList.push(payloadAuthListItem);
      }
      for (let createAssignIndex = 0; createAssignIndex < this.d_createAssign.length; createAssignIndex++) {
        let payloadAuthListItem = {
          'auth_code': '',
          'auth_id': '',
          'auth_type': '',
          'auth_count_create': '',
          'can_assign_auth': '',
          'auth_count': '0',
          'auth_count_type': '0',
          'start_date': '',
          'end_date': '',
          'auth_label': '',
          'auth_label_lang': ''
        };
        if (this.d_createAssign[createAssignIndex]['auth_count_create']) {
          payloadAuthListItem['auth_count_create'] = this.d_createAssign[createAssignIndex]['auth_count_create'];
          payloadAuthListItem['auth_count_type'] = '2' //yetki sayısının üzerine eklenecek şekilde
        } else {
          payloadAuthListItem['auth_count_type'] = '0' //yetki adedi yok şeklinde
        }
        if (this.d_createAssign[createAssignIndex]['auth_count']) {
          payloadAuthListItem['auth_count'] = this.d_createAssign[createAssignIndex]['auth_count'];
        }
        if (this.d_createAssign[createAssignIndex]['can_assign_auth']) {
          payloadAuthListItem['can_assign_auth'] = this.d_createAssign[createAssignIndex]['can_assign_auth'];
        }
        // admin için yetki adet tipi aktifleştirilirse burası da açılacak ki oradaki inputtan alınsın
        // if (this.d_createAssign[createAssignIndex]['auth_count_type']) {
        //   payloadAuthListItem['auth_count_type'] = this.d_createAssign[createAssignIndex]['auth_count_type'];
        // }
        if (this.d_createAssign[createAssignIndex]['start_date']) {
          payloadAuthListItem['start_date'] = this.d_createAssign[createAssignIndex]['start_date'];
        }
        if (this.d_createAssign[createAssignIndex]['end_date']) {
          payloadAuthListItem['end_date'] = this.d_createAssign[createAssignIndex]['end_date'];
        }
        if (this.d_createAssign[createAssignIndex]['auth_code']) {
          payloadAuthListItem['auth_code'] = this.d_createAssign[createAssignIndex]['auth_code'];
        }
        if (this.d_createAssign[createAssignIndex]['auth_id']) {
          payloadAuthListItem['auth_id'] = this.d_createAssign[createAssignIndex]['auth_id'];
        }
        if (this.d_createAssign[createAssignIndex]['auth_type']) {
          payloadAuthListItem['auth_type'] = this.d_createAssign[createAssignIndex]['auth_type'];
        }
        if (this.d_createAssign[createAssignIndex]['auth_label']) {
          payloadAuthListItem['auth_label'] = this.d_createAssign[createAssignIndex]['auth_label'];
        }
        // auth lang label şuan devre dışı eğer aktifleştirilirse burası da açılacak.
        // if (this.d_createAssign[createAssignIndex]['auth_label_lang']) {
        //   payloadAuthListItem['auth_label_lang'] = this.d_createAssign[createAssignIndex]['auth_label_lang'];
        // }
        payloadAuthList.push(payloadAuthListItem);
      }
      let query = '';
      let data = {
        'auth_list': payloadAuthList,
        'assigning_username_list': this.d_newAssign.users,
      };
      // console.log(data);
      ServiceFunctions.authorization_assign_to_user(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            // console.log(resp.data);

          }
        });
    }
  },
  watch:{
    'd_newAssign.auths': function () {
      // console.log('this.d_newAssign.auths');
      // console.log(this.d_newAssign.auths);
    },
    'd_newAssign.users': function () {
      // console.log("d_newAssign.users");
      // console.log(this.d_newAssign.users);
    },
    'd_isAdmin': function () {
      console.log("d_isAdmin");
      console.log(this.d_isAdmin);
    },
    'd_frontendClientID': function () {
      this.f_frontendWebsiteunitList();
    }
  }

}

</script>

